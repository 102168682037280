<!-- 素材添加、收藏分类窗口 -->
<template>
  <div class="FileDialog">
    <!-- 标题 -->
    <div class="FileDialog-header">
      <div class="FileDialog-header-title">{{ fileTitle }}</div>
      <div class="FileDialog-header-close">
        <i class="icon icon-list-fileClose" @click="fileClose"></i>
      </div>
    </div>
    <!-- 分类 -->
    <div class="FileDialog-file">
      <el-tree
        ref="tree"
        :data="treeData"
        :props="props"
        node-key="id"
        :expand-on-click-node="false"
        :show-checkbox="true"
        @check="handleCheck"
      >
        <div :class="['custom-tree-node']" slot-scope="{ data }">
          <template>
            <div class="custom-tree-node-text">
              <el-tooltip
                :content="data.name"
                effect="light"
                placement="top"
                :enterable="false"
                :disabled="data.name.length < 12"
              >
                <span class="custom-tree-node-name">{{ data.name }}</span>
              </el-tooltip>
            </div>
          </template>
        </div>
      </el-tree>
    </div>
    <!-- 新建分类 -->
    <div class="FileDialog-addFile">
      <el-input
        placeholder="新建分类"
        v-model="fileName"
        maxlength="20"
        show-word-limit
        class="FileDialog-addFile-input"
      >
        <div slot="append" class="FileDialog-addFile-input-icon">
          <i class="icon icon-list-fileAdd" @click="addFile()"></i>
        </div>
      </el-input>
    </div>
  </div>
</template>

<script>
import $bus from 'p/utils';
export default {
  name: 'FileDialog',
  data() {
    return {
      fileName: '',
      fileTitle: '选择分类',
      fileData: [],
      // 树状图配置
      props: {
        label: 'name',
        id: 'id'
      },
      // 类型
      type: ''
    };
  },
  computed: {
    // 树状图数据
    treeData() {
      return [].concat(this.fileData);
    }
  },
  methods: {
    // 关闭时调用
    refreshClassify(val) {
      this.fileData = [];
      if (!val) {
        $bus.$emit('refreshClassify');
      }
    },
    getFileData(type) {
      if (type) {
        this.type = type;
      }
      const params = {
        type: this.type,
        selectSystem: 0
      };
      this.$service.materialLibrary
        .styleResourceCategoryAll(params)
        .then(res => {
          if (res.data.status === 200) {
            this.fileData = res.data.data;
          }
        });
    },

    fileClose() {
      this.$emit('dropdownClose', '');
    },
    handleCheck(data) {
      this.$emit('handleCheck', data.id);
    },
    // 新增分类
    addFile() {
      if (!this.fileName) return this.$message.warning('请输入分类名称');
      const params = {
        type: this.type,
        name: this.fileName
      };
      this.$service.materialLibrary
        .styleResourceCategoryAdd(params)
        .then(res => {
          if (res.data.status === 200) {
            this.$message.success(res.data.msg);
            this.getFileData();
            $bus.$emit('refreshClassify1');
            this.fileName = '';
          }
        });
    }
  }
};
</script>

<style lang="less" scoped>
.FileDialog {
  width: 198px;
  height: 214px;
  background: #ffffff;
  box-shadow: 0px 6px 8px 0px rgba(51, 51, 51, 0.2),
    0px -4px 8px 0px rgba(51, 51, 51, 0.1);
  border-radius: 8px;
  overflow: hidden;
  // 头部标题
  &-header {
    width: 198px;
    height: 35px;
    background: #7396ff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 6px;
    &-title {
      font-size: 14px;
      line-height: 35px;
      margin-left: 10px;
      color: #ffffff;
      user-select: none;
    }
    &-close {
      margin-right: 10px;
      cursor: pointer;
    }
  }
  // 分类
  &-file {
    height: 127px;
    overflow: hidden;
    overflow-y: auto;
  }
  &-addFile {
    padding: 15px 10px 0;
    /deep/&-input {
      .el-input {
        &__inner {
          font-size: 12px;
          height: 24px;
          width: 150px;
          overflow: hidden;
          background: #ffffff;
          border: 1px solid #b5b5b5;
          border-radius: 4px 0px 0px 4px;
          &:focus {
            border: 1px solid #507cff;
          }
        }
        /deep/&-group__append {
          width: 24px;
          height: 20px;
          background: #ffffff;
          border: none;
        }
      }
      &-icon {
        display: flex;
        align-items: center;
        top: 0px;
        margin-left: -20px;
        width: 24px;
        height: 22px;
        background: #b5b5b5;
        border: 1px solid #b5b5b5;
        border-radius: 0px 4px 4px 0px;
        cursor: pointer;
        .icon-list-fileAdd {
          margin: 0 auto;
        }
        &:hover {
          background: #507cff;
          border: 1px solid #507cff;
        }
      }
    }
  }
}
/deep/.el-tree-node__content {
  padding-left: 20px;
  height: 26px;
  line-height: 0;
}
/deep/.el-checkbox__inner {
  position: absolute;
  line-height: 0;
  top: 4px;
  margin-left: 20px;
  border: 1px solid #f6f4f4 !important;
}

// 自定义节点
.custom-tree-node {
  display: flex;
  align-items: center;
  height: 24px;
  padding: 0px 20px;
  color: #333;
  font-size: 14px;
  &-name {
    margin-left: 20px;
  }
  // 隐藏左侧箭头标
  .icon-arrow-right-filling:before {
    content: '';
    transform: none;
    position: absolute;
    margin-top: -20px;
    left: 40px;
    width: 180px;
    height: 38px;
  }
}
</style>
