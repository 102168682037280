<template>
  <div class="assetsBin">
    <!-- 标题、新增分类 -->
    <div class="assetsBin-header">
      <div class="assetsBin-header-left">
        {{ fileTitle }}
      </div>
      <list-tooltip content="新增分类">
        <i
          class="icon icon-file-add"
          @click.stop="handleAdd()"
          v-show="fileTitle !== '系统'"
        ></i
      ></list-tooltip>
    </div>
    <div
      class="assetsBin-pleaseTellall"
      @click.stop="getTableData()"
      :style="{ background: [currentId ? '' : '#f1f5ff'] }"
    >
      <i class="icon icon-file"></i>
      <span
        class="assetsBin-pleaseTellall-text"
        :style="{ color: [currentId ? '' : '#507cff'] }"
        >全部</span
      >
    </div>
    <!-- 分类数据 -->
    <el-table
      class="assetsBin-table"
      ref="tableRef"
      :data="tableData"
      :show-header="false"
      @cell-mouse-enter="handleCellMouseEnter"
      @cell-mouse-leave="handleCellMouseLeave"
      :row-class-name="({ row }) => row.id"
      :cell-class-name="tableRowClassName"
      :row-key="row => row.id"
    >
      <el-table-column>
        <template slot-scope="scope">
          <div
            class="assetsBin-table-list"
            @click.stop="getTableData(scope.row)"
          >
            <div class="assetsBin-table-list-left">
              <!-- 拖拽分类更改排序 -->
              <div class="assetsBin-table-list-icon">
                <i
                  class="icon icon-file-move1"
                  v-show="
                    iconShowId !== scope.row.id ||
                    scope.row.defaultCategory === 1 ||
                    fileTitle === '系统'
                  "
                ></i>
                <list-tooltip content="按住鼠标左键拖拽移动">
                  <i
                    class="icon icon-file-move"
                    v-show="
                      iconShowId === scope.row.id &&
                      scope.row.defaultCategory !== 1 &&
                      fileTitle !== '系统'
                    "
                  ></i>
                </list-tooltip>
                <i class="icon icon-file"></i>
              </div>
              <!-- 分类编辑与文本显示 -->
              <EditText
                v-if="scope.row.id == $store.state.common.editTextId"
                class="editText"
                :id="scope.row.id"
                :value="scope.row.name"
                :maxlength="20"
                @edit="text => handleNameEdit(text, scope.row)"
                @del="handleNameDel(scope.row)"
              >
              </EditText>
              <template v-else>
                <el-tooltip
                  :content="scope.row.name"
                  :disabled="scope.row.name.length < 12"
                  placement="bottom"
                  :enterable="false"
                  transition="none"
                  :visible-arrow="false"
                  popper-class="atooltip1"
                >
                  <span
                    class="folderName"
                    :style="{
                      color: [currentId === scope.row.id ? '#507cff' : '']
                    }"
                  >
                    {{ scope.row.name }}
                  </span>
                </el-tooltip>
              </template>
            </div>
            <!-- 右侧功能按钮 -->
            <div
              class="assetsBin-table-list-right"
              v-show="
                iconShowId === scope.row.id &&
                scope.row.defaultCategory !== 1 &&
                fileTitle !== '系统'
              "
            >
              <list-tooltip content="编辑">
                <i
                  class="icon icon-file-edit"
                  @click.stop="handleEdit(scope.row)"
                ></i>
              </list-tooltip>
              <list-tooltip content="删除">
                <i
                  class="icon icon-file-del"
                  @click.stop="handleDel(scope.row)"
                ></i>
              </list-tooltip>
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import $bus from 'p/utils';
import { mapState } from 'vuex';
import EditText from 'c/basic/EditText';
import ListTooltip from 'c/basic/ListTooltip.vue';
import Sortable from 'sortablejs';
export default {
  name: 'AssetsBin',
  components: {
    EditText,
    ListTooltip
  },
  data() {
    return {
      // 当前分类
      fileTitle: '系统',
      // 当前素材库
      listTitle: '',
      // 当前分类Id
      currentId: '',
      // 分类数据
      tableData: [],
      // 排序后的数据列表
      newIndexList: [],
      // 鼠标经过显示按钮
      iconShowId: null,
      // 文字提示
      isTooltip: false,
      // 系统分类显示用于请求素材数据
      selectSystem: '1',
      // 分类类型编号用于请求素材数据
      typeNum: ''
    };
  },
  computed: {
    ...mapState('materialLibrary', ['currentLibraryData', 'dataSourceId']),
    editTextId: {
      get() {
        return this.$store.state.common.editTextId;
      }
    }
  },
  mounted() {
    var that = this;
    // 每一次调整位置会对newIndexList进行位置调整。
    that.initSortableList();
    $bus.$off('refreshClassify');
    $bus.$off('refreshClassify');
    $bus.$on('refreshClassify', () => {
      this.getClassifyData(this.getTypeNum());
    });
    $bus.$on('refreshClassify1', () => {
      this.getClassifyData(this.getTypeNum(), 1);
    });
  },
  methods: {
    // 获取分类类型编号
    getTypeNum(val) {
      if (val == 1) {
        this.$emit('emptyList');
        $bus.$emit('emptyText');
      }
      let params = {
        categoryId: this.currentId,
        system: this.selectSystem,
        typeNum: this.typeNum,
        keywords: ''
      };
      this.$store.commit('materialLibrary/setStyleCurrentPaged', 1);
      if (this.currentLibraryData === 'iconLibrary') {
        switch (this.dataSourceId) {
          case 1:
            this.typeNum = '1';
            this.selectSystem = '1';
            break;
          case 2:
            this.typeNum = '1';
            this.selectSystem = '0';
            break;
          case 3:
            this.typeNum = '5';
            this.selectSystem = '0';
            break;
        }
        if (val == 1) {
          this.$emit('category', params);
        }
        return this.typeNum;
      }
      if (this.currentLibraryData === 'polylineLibrary') {
        switch (this.dataSourceId) {
          case 1:
            this.typeNum = '2';
            this.selectSystem = '1';
            break;
          case 2:
            this.typeNum = '2';
            this.selectSystem = '0';
            break;
          case 3:
            this.typeNum = '6';
            this.selectSystem = '0';
            break;
        }
        if (val == 1) {
          this.$emit('category', params);
        }
        return this.typeNum;
      }
      if (this.currentLibraryData === 'planeLibrary') {
        switch (this.dataSourceId) {
          case 1:
            this.typeNum = '9';
            this.selectSystem = '1';
            break;
          case 2:
            this.typeNum = '9';
            this.selectSystem = '0';
            break;
          case 3:
            this.typeNum = '10';
            this.selectSystem = '0';
            break;
        }
        if (val == 1) {
          this.$emit('category', params);
        }
        return this.typeNum;
      }
      if (this.currentLibraryData === 'styleLibrary') {
        switch (this.dataSourceId) {
          case 1:
            this.typeNum = '4';
            this.selectSystem = '1';
            break;
          case 2:
            this.typeNum = '4';
            this.selectSystem = '0';
            break;
          case 3:
            this.typeNum = '8';
            this.selectSystem = '0';
            break;
        }
        if (val == 1) {
          this.$emit('category', params);
        }
        return this.typeNum;
      }
    },
    // 获取分类数据
    getClassifyData(type, val) {
      if (val != 1) {
        this.$emit('select', false);
      }
      const params = {
        type: type,
        selectSystem: this.selectSystem
      };
      this.$service.materialLibrary
        .styleResourceCategoryAll(params)
        .then(res => {
          if (res.data.status === 200) {
            this.tableData = res.data.data;
            if (val != 1) {
              this.getTypeNum(1);
            }
          }
        });
    },

    // 更新排序
    initSortableList() {
      let el = this.$refs.tableRef.$el.querySelector(
        // '.el-table__body-wrapper tbody'
        '.el-table__body > tbody'
      );
      //设置配置
      let _this = this;
      Sortable.create(el, {
        animation: 150,
        sort: true,
        handle: '.icon-file-move', // 设置可拖拽行的类名
        filter: '.folderName',
        draggable: '.el-table__row',
        forceFallback: true,
        onEnd(evt) {
          if (evt.newIndex === 0 || evt.oldIndex === 0) {
            _this.tableData = [];
            _this.getClassifyData(_this.getTypeNum());
            _this.$message('默认分类位置禁止修改');
            return;
          }
          if (evt.newIndex != evt.oldIndex) {
            _this.newIndexList = [];
            Array.from(evt.to.rows).forEach(item => {
              _this.newIndexList.push(item.className.split(' ')[1]);
            });
            _this.save(_this.newIndexList);
          }
        }
      });
    },
    // 保存排序提交
    save(list) {
      let params = new FormData();
      let type = this.getTypeNum();
      params.append('type', type);
      list.forEach(item => {
        const element = item;
        params.append('orderId', element);
      });

      this.$service.materialLibrary
        .styleResourceCategoryOrder(params)
        .then(res => {
          if (res.data.status === 200) {
            this.$message.success(res.data.msg);
            this.getClassifyData(this.getTypeNum());
          }
        });
    },
    // 获取列表数据
    getTableData(row) {
      if (row) {
        if (this.editTextId == row.id || row.id < 10000000) return;
        this.currentId = row.id;
      } else {
        this.currentId = '';
      }
      this.getTypeNum(1);
      this.$emit('select', false);
      this.$store.commit(
        'materialLibrary/setStyleCurrentClassifyid',
        this.currentId
      );
    },
    // 行内按钮显示
    handleCellMouseEnter(row) {
      if (this.editTextId) return;
      this.iconShowId = row.id;
    },
    // 行内按钮隐藏
    handleCellMouseLeave() {
      if (this.editTextId) return;
      this.iconShowId = null;
    },
    // 选中行颜色
    tableRowClassName(row) {
      if (this.currentId == row.row.id) {
        return 'warm-row';
      }
    },
    // 文件文本编辑
    handleNameEdit(text, data) {
      if (!text) return this.$message.warning('名称不能为空');
      let params = {};
      let req = '';
      if (data.id < 10000000) {
        // 新增分类
        req = 'styleResourceCategoryAdd';
        params = {
          name: text,
          type: this.getTypeNum()
        };
      } else {
        // 编辑分类
        req = 'styleResourceCategoryModifyName';
        params = {
          id: data.id,
          name: text
        };
      }
      this.$service.materialLibrary[req](params).then(res => {
        if (res.data.status == 200) {
          this.$message.success(res.data.msg);
          this.iconShowId = null;
          this.$store.commit('common/setEditTextId', '');
          this.getClassifyData(this.getTypeNum());
        }
      });
    },
    // 退出编辑
    handleNameDel() {
      this.$store.commit('common/setEditTextId', '');
      this.getClassifyData(this.getTypeNum());
    },
    // 新增分类
    handleAdd() {
      if (this.editTextId) return;
      var newChild = {
        id: Math.floor(Math.random() * 10000 + 100000) + '',
        name: ''
      };
      this.tableData.push(newChild);
      this.$store.commit('common/setEditTextId', newChild.id);
    },
    // 重命名
    handleEdit(row) {
      this.$store.commit('common/setEditTextId', row.id);
      this.iconShowId = '0';
    },
    // 删除分类
    handleDel(row) {
      let currentId = this.currentId;
      this.currentId = row.id;
      let confirmText = '';
      let val = this.currentLibraryData;
      switch (val) {
        case 'iconLibrary':
          confirmText = '引用分类中图标的所有数据将无法展示，确定要删除吗？';
          break;
        case 'polylineLibrary':
          confirmText = '引用分类中线段的所有数据将无法展示，确定要删除吗？';
          break;
        case 'planeLibrary':
          confirmText = '引用分类中多边形的所有数据将无法展示，确定要删除吗？';
          break;
        case 'styleLibrary':
          confirmText =
            '引用分类中样式的所有数据将会被设为默认样式，确定要删除吗？';
          break;
      }
      this.$confirm(confirmText, '删除确认', {
        confirmButtonText: '删除',
        cancelButtonText: '取消',
        type: 'warning',
        customClass: 'del'
      })
        .then(() => {
          this.currentId = currentId;
          const params = {
            id: row.id
          };
          this.$service.materialLibrary
            .styleResourceCategoryDel(params)
            .then(res => {
              if (res.data.status === 200) {
                this.getClassifyData(this.getTypeNum());
                this.$message.success(res.data.msg);
              }
            });
        })
        .catch(() => {
          this.currentId = currentId;
        });
    }
  },
  watch: {
    // 监听数据来源
    dataSourceId: {
      immediate: true,
      handler(id) {
        this.$nextTick(() => {
          this.getClassifyData(this.getTypeNum());
        });
        this.currentId = '';
        this.$store.commit('materialLibrary/setStyleCurrentClassifyid', '');
        this.$store.commit('common/setEditTextId', '');

        switch (id) {
          case 1:
            this.fileTitle = '系统';
            break;
          case 2:
            this.fileTitle = '我的';
            break;
          case 3:
            this.fileTitle = '收藏';
            break;
        }
      }
    },
    // 监听数据来源
    currentLibraryData: {
      immediate: true,
      handler(val) {
        switch (val) {
          case 'iconLibrary':
            this.listTitle = '图标';
            break;
          case 'polylineLibrary':
            this.listTitle = '线段';
            break;
          case 'planeLibrary':
            this.listTitle = '多边形';
            break;
          case 'styleLibrary':
            this.listTitle = '样式';
            break;
        }
        this.placeholder = '在“' + this.fileTitle + '”中搜索' + this.listTitle;
      }
    }
  }
};
</script>

<style lang="less" scoped>
//素材文件夹
.assetsBin {
  user-select: none;
  min-height: 53px;
  max-height: 480px;
  height: 177px;
  overflow-y: auto;
  border-bottom: 1px solid #d2d2d2;
  resize: vertical;
  // 文件夹标题
  &-header {
    background: #fff;
    position: absolute;
    top: 227px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 46px;
    padding-left: 20px;
    width: 100%;
    color: #333333;
    font-size: 18px;
    border-bottom: 1px solid #d2d2d240;
    .icon-file-add {
      margin-right: 40px;
    }
  }
  // 查看所有
  &-pleaseTellall {
    display: flex;
    margin-top: 46px;
    cursor: pointer;
    height: 35px;
    .icon {
      margin-top: 6px;
      margin-left: 20px;
    }
    &-text {
      font-size: 16px;
      margin-top: 7px;
    }
    &:hover {
      background: #f1f5ff;
      .assetsBin-pleaseTellall-text {
        color: #507cff;
      }
    }
  }
  // 表格
  &-table {
    border-radius: 0px;
    // 行高
    /deep/.el-table__cell {
      height: 32px;
      padding: 0;
      line-height: 32px;
      border: none;
      cursor: pointer;
    }
    // 行样式
    &-list {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;
      .icon {
        width: 16px;
        height: 16px;
        cursor: pointer;
        margin-top: 5px;
      }
      .icon-file,
      .icon-file-move1 {
        width: 21px;
        height: 18px;
        cursor: default;
      }
      &-left {
        margin-left: 10px;
        height: 30px;
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        .editText {
          margin: -31px 5px 0 50px;
          min-width: 200px;
        }
        .folderName {
          display: flex;
          margin: -25px 5px 0 50px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          &:hover {
            color: #507cff;
            cursor: pointer;
          }
        }
      }
      &-right {
        display: flex;
        align-items: center;
        margin-right: 10px;
        .icon-file-edit {
          margin-right: 4px;
        }
      }
    }
  }
}
/deep/.editText-ipt {
  &__inner {
    padding-right: 45px;
    height: 28px;
    line-height: 38px;
  }
  &-showWordLimit {
    right: 90px;
    font-size: 12px;
  }
  .btn {
    margin-left: 8px;
  }
}
// 表格行背景色
/deep/.warm-row {
  background-color: #f1f5ff !important;
}
</style>
