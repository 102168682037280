<template>
  <el-tooltip
    effect="dark"
    :content="content"
    :placement="placement"
    :visible-arrow="false"
    :enterable="false"
    transition="none"
    popper-class="atooltip"
  >
    <slot>{{ content }}</slot>
  </el-tooltip>
</template>

<script>
export default {
  name: 'Tooltip',
  props: {
    // 用于外部调用
    refName: {
      type: String,
      default: 'content'
    },
    //  兼容el-tooltip显示的内容
    content: {
      type: String,
      default: ''
    },
    //  Tooltip 的出现位置,由于项目需要此处默认顶部
    placement: {
      type: String,
      default: 'bottom'
    }
  },
  data() {
    return {
      isDisabled: false // tooltip是否可见
    };
  },
  methods: {}
};
</script>

<style lang="less">
.atooltip {
  background: rgba(89, 89, 89, 0.8) !important;
  padding: 5px 8px !important;
  margin: 0;
  font-size: 14px;
  user-select: none;
}
// 图层列表、数据管理列表、素材库分类列表
.atooltip1 {
  border: 1px solid #000;
  border-radius: 0 !important;
  color: #000 !important;
  background: #fff !important;
  padding: 5px 8px !important;
  margin: 0;
  font-size: 12px;
  user-select: none;
}
</style>
