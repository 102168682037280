<template>
  <!-- 搜索框 -->
  <div class="searchBox">
    <input
      class="searchBox-ipt"
      type="text"
      :placeholder="placeholder"
      :value="value"
      @input="$emit('input', $event.target.value)"
      @keyup.enter="$emit('search')"
    />
    <!-- :onchange="okok(value)" -->
    <!-- 清空按钮 -->
    <i
      v-show="value"
      class="iconfont icon-close searchBox-btn clearBtn"
      @click="$emit('close')"
      title="清空输入框"
    ></i>
    <!-- 搜索按钮 -->
    <i
      class="icon icon-search searchBox-btn searchBtn"
      @click="$emit('search')"
      title="搜索"
    ></i>
  </div>
</template>

<script>
import $bus from 'p/utils';
export default {
  name: 'SearchBox',
  props: {
    value: String,
    placeholder: {
      type: String,
      default: ''
    }
  },
  mounted() {
    var that = this;
    $bus.$off('emptyText');
    $bus.$on('emptyText', () => {
      that.$emit('close', 1);
    });
  },
  methods: {
    okok() {
      this.$emit('search');
    }
  }
};
</script>

<style lang="less" scoped>
.searchBox {
  position: relative;
  &-ipt {
    box-sizing: border-box;
    width: 100%;
    height: 32px;
    padding-left: 10px;
    background: #ffffff;
    border: 1px solid #c9c9c9;
    border-radius: 12px;
    font-size: 12px;
    &:focus {
      border-color: transparent;
      box-shadow: 0 0 1px 1px #507cff;
    }
    &::placeholder {
      color: #999999;
    }
  }
  &-btn {
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    &.clearBtn {
      right: 30px;
      color: #999;
      font-size: 24px;
    }
    &.searchBtn {
      right: 10px;
    }
  }
}
</style>
