<template>
  <!-- 按钮组件 -->
  <button
    type="button"
    @click="$emit('click')"
    :style="{ 'flex-direction': direction }"
  >
    <!-- 图标 -->
    <i v-if="icon" :class="['icon', 'icon-' + icon]"></i>
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'Button',
  props: {
    direction: {
      type: String,
      default: 'row'
    },
    icon: String
  }
};
</script>

<style lang="less" scoped>
button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 127px;
  height: 36px;
  background: #0096ff;
  border-radius: 8px;
  color: #fff;
  font-size: 20px;
  &:active {
    filter: brightness(150%);
  }
  .icon {
    position: relative;
    top: 1px;
    margin-right: 5px;
  }
}
</style>
