<template>
  <div class="assetsList">
    <!-- 头部 -->
    <div class="assetsList-header">
      <div class="assetsList-header-left">{{ listTitle }}</div>
      <list-tooltip :content="'新增' + listTitle">
        <i
          :class="
            styleUP ? 'icon icon-list-uploading1' : 'icon icon-list-uploading'
          "
          @click="handleUploading(currentLibraryData)"
          v-show="dataSourceId === 2"
        ></i>
      </list-tooltip>
    </div>
    <!-- 搜索框 -->
    <assets-search-box
      :placeholder="placeholder"
      v-model="keyword"
      @search="handleSearch"
      @close="handleSearchClose"
    />
    <!-- 上传选择框 -->
    <div class="assetsList-styleUp" v-show="styleUP">
      <div class="assetsList-styleUp-text" @click="handleStyleUp('local')">
        本地样式文件
      </div>
      <div class="assetsList-styleUp-text" @click="handleStyleUp('import')">
        导入代码样式
      </div>
    </div>
    <div class="assetsList-bottom">
      <!-- 分页器 -->
      <div class="assetsList-pagination">
        <div class="box">
          <el-pagination
            layout="prev, pager, next"
            :page-size="paging.size"
            :total="paging.total"
            :current-page="paging.page"
            @current-change="handleCurrentChange"
            hide-on-single-page
          >
          </el-pagination>
        </div>
      </div>
      <!-- 底部 -->
      <div class="assetsList-select" v-show="dataSourceId !== 1">
        <!-- 选择 -->
        <div class="assetsList-select-left">
          <Button class="form-btn" v-show="!select" @click="handleSelect(false)"
            >选择</Button
          >
          <Button class="form-btn" v-show="select" @click="handleAllChecked">{{
            selectText
          }}</Button>
          <Button
            class="form-btn cancel"
            v-show="select"
            @click="handleSelect(true)"
            >取消</Button
          >
        </div>
        <div class="assetsList-select-right">
          <!-- 添加到文件 -->
          <el-dropdown
            trigger="click"
            :disabled="checkedArr.length < 1"
            ref="dropdown"
            @visible-change="val => dropdownChange(val)"
            :hide-on-click="false"
          >
            <list-tooltip content="移动到其他分类">
              <i
                :class="
                  checkedArr.length >= 1
                    ? 'icon icon-list-addFile1'
                    : 'icon icon-list-addFile'
                "
                @click="hendleAddFile(checkedArr)"
              ></i>
            </list-tooltip>
            <el-dropdown-menu slot="dropdown">
              <FileDialog
                @dropdownClose="dropdownClose"
                @handleCheck="handleCheck"
                ref="FileDialog"
              />
            </el-dropdown-menu>
          </el-dropdown>

          <!-- 删除 -->
          <el-tooltip
            popper-class="atooltip"
            transition="none"
            :visible-arrow="false"
            :enterable="false"
            :manual="true"
            v-model="isShow"
            @mouseenter.native.prevent="delPut"
            @mouseleave.native.prevent="delLeave"
            :content="dataSourceId === 3 ? '取消收藏' : '删除'"
          >
            <i
              :class="
                checkedArr.length >= 1
                  ? 'icon icon-list-del1'
                  : 'icon icon-list-del'
              "
              @click.stop="hendleDel(checkedArr)"
            ></i>
          </el-tooltip>
        </div>
      </div>
    </div>
    <!-- 收藏提示 -->
    <div class="assetsList-message" v-show="messageBox">
      {{ messageText }}
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import AssetsSearchBox from 'c/basic/AssetsSearchBox.vue';
import Button from 'c/basic/Button.vue';
import FileDialog from 'c/basic/FileDialog.vue';
import ListTooltip from 'c/basic/ListTooltip.vue';
export default {
  name: 'AssetsList',
  components: {
    AssetsSearchBox,
    Button,
    FileDialog,
    ListTooltip
  },
  props: ['checkedArr', 'paging', 'select'],
  data() {
    return {
      // 当前素材库
      listTitle: '',
      // 当前分类
      fileTitle: '',
      // 输入框提示语
      placeholder: '',
      // 样式库上传
      styleUP: false,
      // 提示语
      messageText: '请选择内容',
      messageBox: false,
      // 选中的ID
      checkedArrId: [],
      // 上传通用
      upData: {},
      keyword: '',
      // 选择状态文本
      selectText: '全选',
      isShow: false
    };
  },
  computed: {
    ...mapState('materialLibrary', [
      'currentLibraryData',
      'dataSourceId',
      'styleCurrentPaged',
      'styleCurrentClassifyid'
    ])
  },
  methods: {
    dropdownChange(val) {
      if (val) {
        let title = this.listTitle;
        let type = '';
        switch (title) {
          case '图标':
            type = this.dataSourceId === 3 ? '5' : '1';
            break;
          case '线段':
            type = this.dataSourceId === 3 ? '6' : '2';
            break;
          case '多边形':
            type = this.dataSourceId === 3 ? '10' : '9';
            break;
          case '样式':
            type = this.dataSourceId === 3 ? '8' : '4';
            break;
        }
        this.$refs.FileDialog.getFileData(type);
      } else {
        this.$refs.FileDialog.refreshClassify(1);
      }
    },

    // 上传
    handleUploading(val) {
      switch (val) {
        case 'iconLibrary':
          (this.upData = {
            title: '上传图标',
            status: true,
            categoryId: this.styleCurrentClassifyid
          }),
            this.$store.commit(
              'materialLibrary/setAddIconDialogVisible',
              this.upData
            );
          break;
        case 'polylineLibrary':
          (this.upData = {
            title: '新增线段样式',
            status: true,
            categoryId: this.styleCurrentClassifyid
          }),
            this.$store.commit(
              'materialLibrary/setAddPolylineStyleDialogVisible',
              this.upData
            );
          break;
        case 'planeLibrary':
          (this.upData = {
            title: '新增多边形样式',
            status: true,
            categoryId: this.styleCurrentClassifyid
          }),
            this.$store.commit(
              'materialLibrary/setAddPlaneDialogVisible',
              this.upData
            );
          break;
        case 'styleLibrary':
          this.styleUP = !this.styleUP;

          break;
      }
    },
    handleStyleUp(val) {
      this.upData = {
        title: val == 'import' ? '导入代码样式' : '导入本地样式',
        status: true,
        categoryId: this.styleCurrentClassifyid
      };
      this.$store.commit(
        'materialLibrary/setAddStyleDialogVisible',
        this.upData
      );
      this.styleUP = false;
    },
    // 选择
    handleSelect(val) {
      this.$emit('select', !val);
      this.$emit('handleAllChecked', false);
    },
    // 全选和取消全选文本
    handleSelectText(text) {
      this.selectText = text;
    },
    // 删除或者取消收藏
    hendleDel(checkedArr) {
      if (checkedArr[0]) {
        // 删除
        if (this.dataSourceId !== 3) {
          let confirmText = '';
          let styleDel = '';

          let val = this.listTitle;
          switch (val) {
            case '图标':
              confirmText = '引用该图标的所有数据将无法展示，确定要删除吗？';
              styleDel = 'styleIconDel';

              break;
            case '线段':
              confirmText = '引用该线段的所有数据将无法展示，确定要删除吗？';
              styleDel = 'styleLineDelBatch';
              break;
            case '多边形':
              confirmText = '引用该多边形的所有数据将无法展示，确定要删除吗？';
              styleDel = 'stylePolygonDel';
              break;
            case '样式':
              confirmText =
                '引用该样式的所有数据将会被设为默认样式，确定要删除吗？';
              styleDel = 'styleResourceDel';
              break;
          }
          this.$confirm(confirmText, '删除确认', {
            confirmButtonText: '删除',
            cancelButtonText: '取消',
            type: 'warning',
            customClass: 'del'
          })
            .then(() => {
              let formData = new FormData();
              for (let i = 0; i < checkedArr.length; i++) {
                const element = checkedArr[i];
                formData.append('id', element);
              }

              this.$service.materialLibrary[styleDel](formData).then(res => {
                if (res.data.status === 200) {
                  this.$emit('search', this.keyword);
                  this.$emit('select', false);
                  this.$message.success(res.data.msg);
                }
              });
            })
            .catch(() => {});
        } else {
          let styleCollectionDelBatch = '';
          let val = this.listTitle;
          let id = '';
          switch (val) {
            case '图标':
              styleCollectionDelBatch = 'styleIconCollectionDelBatch';
              id = 'iconId';
              break;
            case '线段':
              styleCollectionDelBatch = 'styleLineCollectionDelBatch';
              id = 'lineId';
              break;
            case '多边形':
              styleCollectionDelBatch = 'stylePolygonCollectionDelBatch';
              id = 'polygonId';
              break;
            case '样式':
              styleCollectionDelBatch = 'styleResourceCollectionDelBatch';
              id = 'styleId';
              break;
          }
          // 取消收藏
          let formData = new FormData();
          for (let i = 0; i < checkedArr.length; i++) {
            const element = checkedArr[i];
            formData.append(id, element);
          }
          this.$service.materialLibrary[styleCollectionDelBatch](formData).then(
            res => {
              if (res.data.status === 200) {
                this.$emit('search', this.keyword);
                this.$emit('select', false);
                this.$message.success(res.data.msg);
              }
            }
          );
        }
      } else {
        this.handleMessageBox('请选择内容');
      }
    },
    // 删除按钮文字提示
    delPut() {
      this.isShow = true;
    },
    delLeave() {
      this.isShow = false;
    },
    // 提示框
    handleMessageBox(val) {
      this.messageText = val;
      this.messageBox = true;
      setTimeout(() => {
        this.messageBox = false;
      }, 1000);
    },
    // 关闭添加分类
    dropdownClose() {
      this.$refs.dropdown.visible = false;
    },
    // 添加到分类
    hendleAddFile(checkedArr) {
      if (!checkedArr[0]) {
        this.handleMessageBox('请选择内容');
        return;
      }
    },
    // 添加素材到分类
    handleCheck(targetCategoryId) {
      let dataSourceId = this.dataSourceId;
      let styleMove = '';
      let val = this.listTitle;
      let id = '';
      switch (val) {
        case '图标':
          styleMove =
            dataSourceId !== 3 ? 'styleIconMove' : 'styleIconCollectionMove';
          id = dataSourceId !== 3 ? 'id' : 'iconId';
          break;
        case '线段':
          styleMove =
            dataSourceId !== 3 ? 'styleLineMove' : 'styleLineCollectionMove';
          id = dataSourceId !== 3 ? 'id' : 'lineId';
          break;
        case '多边形':
          styleMove =
            dataSourceId !== 3
              ? 'stylePolygonMove'
              : 'stylePolygonCollectionMove';
          id = dataSourceId !== 3 ? 'id' : 'polygonId';
          break;
        case '样式':
          styleMove =
            dataSourceId !== 3
              ? 'styleResourceMove'
              : 'styleResourceCollectionMove';
          id = dataSourceId !== 3 ? 'id' : 'styleId';
          break;
      }
      let formData = new FormData();
      formData.append('targetCategoryId', targetCategoryId);
      for (let i = 0; i < this.checkedArr.length; i++) {
        const element = this.checkedArr[i];
        formData.append(id, element);
      }
      this.$service.materialLibrary[styleMove](formData).then(res => {
        if (res.data.status === 200) {
          this.handleMessageBox(res.data.msg);
          this.$refs.dropdown.visible = false;
          this.$emit('select', false);
          this.$emit('search', this.keyword);
        }
      });
    },
    // 全选按钮
    handleAllChecked() {
      this.$emit('handleAllChecked', '');
    },
    handleSearch() {
      this.$emit('search', this.keyword);
    },
    handleSearchClose(val) {
      this.keyword = '';
      if (!val) {
        this.$emit('search', this.keyword);
      }
    },
    // 分页
    handleCurrentChange(val) {
      this.$store.commit('materialLibrary/setStyleCurrentPaged', val);
      this.$emit('search', this.keyword);
      this.selectText = '全选';
    }
  },
  watch: {
    // 监听数据来源
    dataSourceId: {
      immediate: true,
      handler(id) {
        switch (id) {
          case 1:
            this.fileTitle = '系统';
            break;
          case 2:
            this.fileTitle = '我的';
            break;
          case 3:
            this.fileTitle = '收藏';
            break;
        }
        this.placeholder = '在“' + this.fileTitle + '”中搜索' + this.listTitle;
      }
    },
    // 监听数据来源
    currentLibraryData: {
      immediate: true,
      handler(val) {
        switch (val) {
          case 'iconLibrary':
            this.listTitle = '图标';
            break;
          case 'polylineLibrary':
            this.listTitle = '线段';
            break;
          case 'planeLibrary':
            this.listTitle = '多边形';
            break;
          case 'styleLibrary':
            this.listTitle = '样式';
            break;
        }
        this.placeholder = '在“' + this.fileTitle + '”中搜索' + this.listTitle;
      }
    }
  }
};
</script>

<style lang="less" scoped>
.assetsList {
  margin: 0 20px 20px;
  // 顶部
  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 48px;
    color: #333333;
    font-size: 18px;
  }
  // 样式库上传
  &-styleUp {
    position: absolute;
    top: 405px;
    right: 50px;
    width: 156px;
    background: #ffffff;
    box-shadow: 0px 6px 8px 0px rgba(51, 51, 51, 0.2),
      0px -4px 8px 0px rgba(51, 51, 51, 0.1);
    border-radius: 8px;
    overflow: hidden;
    &-text {
      width: 156px;
      text-align: center;
      height: 38px;
      line-height: 38px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      cursor: pointer;
      &:hover {
        background: #e3ecff;
      }
    }
  }
  //
  &-bottom {
    position: absolute;
    bottom: 0;
    background: #ffffff;
    height: 70px;
    width: 100%;
    margin-left: -5px;
  }
  // 分页器
  &-pagination {
    position: absolute;
    height: 20px;
    bottom: 47px;
    width: 100%;
    .box {
      margin-left: -18px;
      width: 100%;
      display: flex;
    }
    /deep/ .el-pagination {
      padding: 0;
      margin: 0 auto;
      .el-pager li {
        height: 20px;
        line-height: 21px;
        min-width: 2px;
        font-size: 14px;
        font-weight: 400;
        background: transparent;
      }
      .btn-next,
      .btn-prev {
        background: transparent;
        min-width: 10px;
        width: 10px;
        height: 22px;
        padding: 0;
      }
      .btn-prev {
        margin-right: 5px;
      }
      .el-icon-arrow-right:before,
      .el-icon-arrow-left:before {
        font-size: 14px;
      }
    }
  }
  // 底部
  &-select {
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: 20px;
    height: 24px;
    width: 100%;
    &-left {
      display: flex;
      align-items: center;
      width: 200px;
      .form-btn {
        width: 56px !important;
        height: 24px !important;
        border-radius: 2px !important;
        font-size: 12px !important;
        background: #507cff !important;
      }
      .cancel {
        margin-left: 10px !important;
        background: #d6e0ff !important;
      }
    }
    &-right {
      .icon {
        margin-left: 8px;
      }
      .icon-list-del,
      .icon-list-del1 {
        margin-right: 38px;
      }
    }
  }
  // 提示
  &-message {
    position: absolute;
    left: 34%;
    top: 632px;
    width: 100px;
    height: 30px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 6px;
    color: #ffffff;
    font-size: 14px;
    text-align: center;
    line-height: 30px;
  }
  .icon {
    cursor: pointer;
  }
}

.el-dropdown-menu {
  background: none;
  text-align: justify;
  border: none;
  box-shadow: none;
  border-radius: none;
}
</style>
